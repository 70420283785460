<template>
    <!-- 自定义禁飞区 -->
    <div class="no-fly_cont-dj">
        <div class="no-fly_top">
            <div class="title">
                <div class="title-text">
                    <span>{{$t('noFlyZone.customZone')}}</span>
                </div>
                <div class="download">
                    <img class="icon-upload" src="/img/taskCenter/labelPage/上传.png" @click="openUploadFile" />
                    <!-- <img class="icon-download" src="/img/taskCenter/labelPage/下载.svg" /> -->
                    <el-tooltip class="item" popper-class="draw-tooltip" effect="dark" placement="bottom">
                        <div slot="content">
                            <div class="draw-list" v-for="(item, index) in customShapeList" 
                                :key="item.type + index" @click="drawNoFly(item)">{{$t(item.label)}}</div>
                        </div>
                        <i class="el-icon-circle-plus-outline"></i>
                    </el-tooltip>
                </div>
            </div>
            <div class="search">
                <el-input @keyup.enter.native="handleSearch" :placeholder="$t('noFlyZone.noFlySearch')" size="mini" 
                    clearable v-model="searchForm.searchVal">
                    <i slot="suffix" class="search-icon el-input__icon el-icon-search"></i>
                </el-input>
                <el-cascader @change="handleSearch" :options="areajson" v-model="searchForm.provinces" 
                    :props="{expandTrigger: 'hover', checkStrictly : true}" 
                    clearable filterable :placeholder="$t('noFlyZone.noFlySearchProvinces')" size="mini" />
            </div>
        </div>
        <div class="no-fly_list">
            <div class="no-data" v-if="!noFlyData || !noFlyData.length">
                <i class="el-icon-folder-delete" />
                <p>暂无数据...</p>
            </div>
            <div class="no-fly_list-item temporary" :class="{'active': chooseNoFlyItem.id === item.id}" 
                v-for="(item, index) in noFlyData" :key="item.id + index" @click="chooseNoFly(item, 'selected')">
                <div class="list-item-top">
                    <div class="item-label">{{item.name}}</div>
                    <div class="item-icon">
                        <i class="el-icon-aim" @click.stop="locationItem(item)" />
                        <!-- <img class="icon-download" src="/img/taskCenter/labelPage/下载.svg" alt=""> -->
                        <i class="el-icon-delete" @click.stop="deleteItemData(item)" />
                    </div>
                </div>
                <div class="list-item-bottom" v-if="temporaryTime(item.startTime, item.endTime)">
                    {{temporaryTime(item.startTime, item.endTime)}}</div>
            </div>
        </div>
        <!-- 新增上传禁飞区弹窗 -->
        <AddNoFlyPop ref="noFlyUploadFile" @upLoadEmit="uploadFiles" />
        <!-- 右侧弹窗信息 -->
        <GeneralInformation v-if="isShowInformation" :isCustom="true" :customShapeType="customType" 
            :informationData="informationData"  @closeInformation="closeInformationPop" 
            @addPoint="addNoFlyEntityPoint" @changeValue="updateInformation" />
    </div>
</template>

<script>
import areajson from '@/assets/mapJson/areajson';
import {getNoFlyZoneList, getNoFlyZoneDetail, deleteNoFlyZoneItem, importNoFlyZone} from '@/api/noFlyZone/index';
import GeneralInformation from './components/generalInformation';
import AddNoFlyPop from './components/addNoFlyPop';

export default {
    name: 'customZonePage',
    components: {GeneralInformation, AddNoFlyPop},
    data() {
        return {
            // 省市下拉数据
            areajson: areajson.areajson,
            searchForm: {
                searchVal: '',
                provinces: ''
            },
            noFlyData: [],
            chooseNoFlyItem: {},
            isShowInformation: false,
            informationData: {},
            customType: '',
            // 自定义绘制类型下拉
            customShapeList: [
                {label: 'noFlyZone.drawingPolygon', type: 'polygon'},
                {label: 'noFlyZone.drawingCircle', type: 'circle'}
            ]
        }
    },
    computed: {
        temporaryTime() {
            return function(starTime, endTime) {
                if (starTime || endTime) return `${starTime || ''} ~ ${endTime || ''}`;
                return '';
            }
        }
    },
    mounted() {
        this.getNoFlyZoneData();
        this.$EventBus.$off('noFly-left-update');
        this.$EventBus.$on('noFly-left-update', data => {
            this.chooseNoFlyItem = data;
            this.getNoFlyZoneData(true);
        });
        this.$EventBus.$off('choose-noFly-data');
        this.$EventBus.$on('choose-noFly-data', data => {
            this.chooseNoFly(data, 'noToFly');
        });
        this.$EventBus.$off('noFly-set-information');
        this.$EventBus.$on('noFly-set-information', data => {
            this.setInformationPop(data);
        });
        this.$EventBus.$off('noFly-open-information-plane');
        this.$EventBus.$on('noFly-open-information-plane', this.openInformationPanel);
        this.$EventBus.$off('clear-noFly-Panel');
        this.$EventBus.$on('clear-noFly-Panel', () => {
            this.closeInformationPop();
        });
        this.$EventBus.$off('clear-choose-noFly-data');
        this.$EventBus.$on('clear-choose-noFly-data', () => {
            this.chooseNoFlyItem = {};
            this.isShowInformation = false;
            this.informationData = {};
            this.customType = '';
            this.chooseNoFlyItem = {};
        });
    },
    methods: {
        getNoFlyZoneData(noDrawing) {
            let params = {
                flyZoneType: 5,
                name: this.searchForm.searchVal,
                provinceCode: '',
                cityCode: ''
            };
            if (this.searchForm.provinces && this.searchForm.provinces.length) {
                params.provinceCode = this.searchForm.provinces[0];
                if (this.searchForm.provinces.length > 1) {
                    params.cityCode = this.searchForm.provinces[1];
                }
            }
            if (!noDrawing) this.noFlyData = [];
            !noDrawing && this.closeInformationPop();
            getNoFlyZoneList(params).then(res => {
                if (res.data.code == 200) {
                    this.noFlyData = res.data.data || [];
                    !noDrawing && this.createDrawLayer(this.noFlyData);
                }
            });
        },
        createDrawLayer() {
            let contents = [];
            this.noFlyData && this.noFlyData.map(t => contents.push({...t.content, id: t.id, name: t.name}));
            this.$EventBus.$emit('draw-noFly-data', contents);
        },
        handleSearch() {
            this.getNoFlyZoneData();
        },
        chooseNoFly(data, type) {
            if (this.chooseNoFlyItem.id === data.id) return;
            this.chooseNoFlyItem = data;
            getNoFlyZoneDetail({id: data.id}).then(res => {
                if (res.data.code == 200) {
                    this.setInformationPop(res.data.data);
                    this.openInformationPanel();
                }
            }).catch(() => {
                this.closeInformationPop(true);
            });
            if (type !== 'noToFly') this.locationItem(data);
            (type && type !== 'noToFly') && this.$EventBus.$emit('select-noFly-by-id', data.id);
        },
        setInformationPop(data) {
            this.customType = data ? data.content.type : '';
            this.informationData = Object.assign({}, data);
            this.$EventBus.$emit('set-noFly-data', this.informationData);
        },
        openInformationPanel() {
            this.isShowInformation = true;
        },
        closeInformationPop(status) {
            this.isShowInformation = false;
            this.customType = '';
            this.informationData = {};
            if (!status) {
                this.chooseNoFlyItem = {};
                this.$EventBus.$emit('close-noFly-plan');
            }
        },
        locationItem(data) {
            this.$EventBus.$emit('noFly-location', data);
        },
        deleteItemData(data) {
            this.$confirm('此操作将永久删除不可恢复, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteNoFlyZoneItem({ids: data.id}).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('删除成功!');
                        this.closeInformationPop();
                        let item = this.noFlyData.find(t => t.id === data.id);
                        if (item) {
                            let index = this.noFlyData.indexOf(item);
                            if (index !== -1) {
                                this.noFlyData.splice(index, 1);
                                this.$EventBus.$emit('delete-noFly-success', data);
                            }
                        }
                    }
                });
            });
        },
        updateInformation(data, done, changePoint, isDeletePoint, changerRadius) {
            let params = JSON.parse(JSON.stringify(data));
            // 处理省市
            if (data.provinces && data.provinces.length) {
                let provincesItem = this.areajson.find(t => t.value === data.provinces[0]);
                params.provinceCode = data.provinces[0];
                if (provincesItem) {
                    params.province = provincesItem.label;
                }
                if (data.provinces.length > 1) {
                    params.cityCode = data.provinces[1];
                    if (provincesItem && provincesItem.children) {
                        let cityItem = provincesItem.children.find(t => t.value === data.provinces[1]);
                        if (cityItem) {
                            params.city = cityItem.label;
                        }
                    }
                }
            }
            //  处理回调函数
            let callBack = (res) => {
                if (!res || res.data.code != 200) {
                    done && done('error');
                } else {
                    this.setInformationPop(res.data.data || {});
                    this.getNoFlyZoneData(true);
                    done && done('success');
                }
            }
            delete params.provinces;
            this.$EventBus.$emit('update-noFly', {data: params, done: callBack, changePoint, isDeletePoint, changerRadius});
        },
        drawNoFly(data) {
            this.$EventBus.$emit('draw-noFly', data);
        },
        addNoFlyEntityPoint(data) {
            this.$EventBus.$emit('add-noFly-PolygonPoint', data);
        },

        openUploadFile() {
            this.$refs.noFlyUploadFile.handleOpenImport();
        },
        uploadFiles(data) {
            let formData = new FormData();
            formData.append('file', data.data.fileList[0]);
            formData.append('flyZoneType', 5);
            importNoFlyZone(formData).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导入成功!');
                    data.callback && data.callback();
                    this.getNoFlyZoneData();
                }
            }).finally(() => {
                this.$refs.noFlyUploadFile.loading = false;
            });
        }
    }
}
</script>